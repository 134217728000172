<template>
    <FormWrapper :is-loading="isLoading">
        <PageTitle title="Blue Book Details" slot="title" link="/helpContent/User Detail" />
        <br />
        <DataTable :actions="true"
            :data="bluebookDetails"
            :columns="tableConfig"
            :is-loading="isLoading">
        </DataTable>
        <br />
        <FormRow>
            <div class="col-sm-6">
                <ButtonGroup>
                    <FormButton type="info" @click="()=>{$router.go(-1)}">Back</FormButton>
                </ButtonGroup>
            </div>
        </FormRow>
    </FormWrapper>
</template>

<script>
    import FormWrapper from 'Components/form/FormWrapper';
    import PageTitle from 'Components/layout/PageTitle';
    import DataTable from 'Components/DataTable';
    import loadingMixin from 'Mixins/loadingMixin';
    import {getBluebookDetails} from '../api';
    import tableConfig from './table';
    export default {
        name: "Detail",
        mixins: [loadingMixin],
        components: {
            FormWrapper,
            DataTable,
            PageTitle
        },
        data() {
            return {
                tableConfig,
                bluebookDetails: []
            }
        },
        computed: {
          userData() {
              return this.$store.state.user.user
            }
        },
        watch: {
        },
        created() {
            this.getData()
        },
        methods: {
            getData() {
                setTimeout(() => {
                    this.showLoader();
                    const data = {
                        blueBookId: this.$route.params.id?atob(this.$route.params.id):0
                    };
                    getBluebookDetails(data)
                        .then(this.handleResponse)
                        .catch(this.handleError)
                },
                50
                )
            },
            handleResponse(response) {
                this.hideLoader();
                this.bluebookDetails = [
                    {
                        'Label': 'Name of Employee',
                        'Value': response.data[0].NAME
                    },
                    {
                        'Label': 'Employee Code',
                        'Value': response.data[0].EmpCode
                    },
                    {
                        'Label': 'Department',
                        'Value': response.data[0].Department
                    },
                    {
                        'Label': 'Manager',
                        'Value': response.data[0].manager
                    },
                    {
                        'Label': 'Reporting Location',
                        'Value': response.data[0].ReportingLocation
                    },
                    {
                        'Label': 'Mobile Number',
                        'Value': response.data[0].MobileNo
                    },
                    {
                        'Label': 'Phone 1',
                        'Value': response.data[0].Phone1
                    },
                    {
                        'Label': 'Phone 2',
                        'Value': response.data[0].Phone2
                    },
                    {
                        'Label': 'Emergency Contact Number',
                        'Value': response.data[0].EmergencyContactNo
                    },
                    {
                        'Label': 'Blood Group',
                        'Value': response.data[0]['Blood Group']
                    },
                    {
                        'Label': 'Extension Number',
                        'Value': response.data[0].Extension
                    },
                    {
                        'Label': 'Office E-mail',
                        'Value': response.data[0].Email
                    },
                    {
                        'Label': 'Personal E-mail',
                        'Value': response.data[0].AlternateEmailId
                    },
                    {
                        'Label': 'DID Number',
                        'Value': response.data[0].DIDNo
                    },
                    {
                        'Label': 'Skype Id',
                        'Value': response.data[0].SkypeId
                    }
                ];
            },
        }
    }
</script>