export default [
	{
        title: 'Personal Info Label',
        key: 'Label',
		sortable: false,
        minWidth: 80,
    },
    {
        title: 'Value',
        key: 'Value',
        minWidth: 80,
		sortable: false,
    },
]